import { COLOR } from "constants/styles";
import React, { useState } from "react";

interface CustomProps {
  label?: React.ReactNode;
  defaultChecked: boolean;
  id: string;
  disabled?: boolean;
}
type Props = React.InputHTMLAttributes<HTMLInputElement> & CustomProps;

const Switch = React.forwardRef<HTMLInputElement, Props>(
  ({ label, defaultChecked, disabled, ...rest }: Props, ref) => {
    const [checked, setChecked] = useState(defaultChecked);
    return (
      <>
        <input
          className="react-switch-checkbox"
          type="checkbox"
          ref={ref}
          {...rest}
          onChange={e => {
            setChecked(e.target.checked);
            rest.onChange?.(e);
          }}
          checked={checked}
        />
        <label className="react-switch-label" htmlFor={rest.id}>
          <span className={`react-switch-button`} />
          {label && <span className="real-label">{label}</span>}
        </label>
        <style jsx>{`
          .react-switch-checkbox {
            height: 0;
            width: 0;
            visibility: hidden;
          }

          .react-switch-label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 48px;
            height: 24px;
            background: ${checked ? COLOR.PRIMARY : COLOR.TEXT_SECONDARY};
            border-radius: 48px;
            position: relative;
            transition: background-color 0.2s;
            cursor: pointer;
            pointer-events: ${disabled ? "none" : "auto"};
            margin-bottom: 0;
          }

          .react-switch-label .react-switch-button {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            transition: 0.2s;
            background: ${COLOR.BG_WHITE};
            box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
          }

          .react-switch-checkbox:checked
            + .react-switch-label
            .react-switch-button {
            left: calc(100% - 2px);
            transform: translateX(-100%);
          }

          .react-switch-label:active .react-switch-button {
            width: 25px;
          }

          .real-label {
            margin-left: 55px;
            cursor: auto;
          }
        `}</style>
      </>
    );
  }
);

Switch.displayName = "Switch";
export { Switch };
